export function addFields(e) {
  e.preventDefault()

  let container = this.parentElement.parentElement
  let inputs =
    container.previousElementSibling.querySelectorAll("input, select")
  let valueField = inputs[0]
  let typeField = inputs[1]

  // get last id
  let name_parts = typeField.name.split("_")
  let base_name = name_parts.slice(0, -1).join("_")
  let lastId = parseInt(name_parts.slice(-1).pop(), 10)

  // add new fields
  let newContainer = container.previousElementSibling.cloneNode(true)
  let newValueField = valueField.cloneNode()
  let newTypeField = typeField.cloneNode(true)
  for (const f of [newValueField, newTypeField]) {
    f.name = [base_name, ++lastId].join("_")
    f.id = ["id", base_name, lastId].join("_")

    if (f.tagName.toLowerCase() == "select") {
      f.value = f.children[0].value
    } else {
      f.value = ""
    }

    newContainer
      .querySelector(["#id", base_name, lastId - 2].join("_"))
      .replaceWith(f)
  }
  container.insertAdjacentElement("beforebegin", newContainer)
  registerEventListeners()
}

export function removeFields(e) {
  e.preventDefault()
  if (this.parentElement.parentElement.parentElement.children.length == 2) {
    this.parentElement.parentElement.querySelectorAll("input").forEach((e) => {
      e.value = ""
    })
    this.parentElement.parentElement.querySelectorAll("select").forEach((e) => {
      e.value = e.children[0].value
    })
  } else {
    this.parentElement.parentElement.remove()
  }
}

export function registerEventListeners() {
  for (const b of document.querySelectorAll("button.add-fields")) {
    b.addEventListener("click", addFields)
  }
  for (const b of document.querySelectorAll("button.remove-fields")) {
    b.addEventListener("click", removeFields)
  }
}
