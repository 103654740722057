import "./main.scss"
import "mdn-polyfills/Node.prototype.replaceWith"
import "mdn-polyfills/Node.prototype.remove"
import "mdn-polyfills/NodeList.prototype.forEach"

import { registerEventListeners } from "./js/typed_list_widget.js"
import { ready } from "./js/utils.js"

ready(() => {
  registerEventListeners()
})
